export interface IConfig {
  environment: {
    name: string;
  };
  clubCode: string;
  clubId: string;
  services: {
    url: string;
  };
  azureDirectory: {
    clientId: string;
    tenantId: string;
    customerScopes: string[];
  };
}

export function lookupConfigByDomain(url?: string): IConfig {
  if (!url) {
    return defaultConfigurationContext;
  }
  return (
    configRecords.find((k) => k.domain.exec(url))?.config ||
    defaultConfigurationContext
  );
}

// Only used when there is no provider, which is an unsupported scenario
export const defaultConfigurationContext: IConfig = {
  environment: {
    name: "the-neutral-zone",
  },
  clubCode: "DEFAULT",
  clubId: "00000000-0000-0000-0000-000000000000",
  services: {
    url: "https://localhost",
  },
  azureDirectory: {
    clientId: "invalid-config-no-provider",
    tenantId: "invalid-config-no-provider",
    customerScopes: ["invalid-config-no-provider"],
  },
};

export const configRecords: { domain: RegExp; config: IConfig }[] = [
  {
    domain: /^http:\/\/localhost:3000/,
    config: {
      environment: {
        name: "dev-local",
      },
      services: {
        url:
          process.env.NEXT_PUBLIC_SERVICES_OVERRIDE ||
          "https://sumer.api.development.sumersports.com",
      },
      clubCode: process.env.NEXT_PUBLIC_CLUB_CODE || "NE",
      clubId:
        process.env.NEXT_PUBLIC_CLUB_ID ||
        "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
      azureDirectory: {
        clientId: "6fb0fa03-36aa-46c5-b7fb-3684b25d9e85",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^http:\/\/localhost:3001/,
    config: {
      clubCode: "GB",
      clubId: "63d59843-d136-5f8b-9c55-de09a20ab321",
      environment: {
        name: "cypress-e2e",
      },
      services: {
        url: "https://sumer.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "d6cb3d86-9004-4687-a402-de9300d24596",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/marval-[a-zA-Z0-9\-]{1,24}-sumersports\.vercel\.app/,
    config: {
      clubCode: "NE",
      clubId: "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
      environment: {
        name: "dev-vercel-branch",
      },
      services: {
        url: "https://sumer.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "6fb0fa03-36aa-46c5-b7fb-3684b25d9e85",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/clubsumer\.marval-dev\.sumersports\.com/,
    config: {
      clubCode: "NE",
      clubId: "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
      environment: {
        name: "dev-clubsumer",
      },
      services: {
        url: "https://sumer.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "6fb0fa03-36aa-46c5-b7fb-3684b25d9e85",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/cheesehead\.marval-dev\.sumersports\.com/,
    config: {
      clubCode: "GB",
      clubId: "63d59843-d136-5f8b-9c55-de09a20ab321",
      environment: {
        name: "dev-clubsumer",
      },
      services: {
        url: "https://sumer.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "6fb0fa03-36aa-46c5-b7fb-3684b25d9e85",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/vault\.marval-dev\.sumersports\.com/,
    config: {
      clubCode: "CAR",
      clubId: "b0afc250-bc45-565b-ab31-c09e2d5209e8",
      environment: {
        name: "dev-panthers",
      },
      services: {
        url: "https://panthers.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "6fb0fa03-36aa-46c5-b7fb-3684b25d9e85",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/hickory\.marval-dev\.sumersports\.com/,
    config: {
      clubCode: "JAX",
      clubId: "b0d7698e-edf7-5afe-98fe-85827218adb9",
      environment: {
        name: "dev-jaguars",
      },
      services: {
        url: "https://jaguars.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "6fb0fa03-36aa-46c5-b7fb-3684b25d9e85",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/clubsumer\.marval-stg\.sumersports\.com/,
    config: {
      clubCode: "NE",
      clubId: "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
      environment: {
        name: "stg-clubsumer",
      },
      services: {
        url: "https://sumer.api.staging.sumersports.com",
      },
      azureDirectory: {
        clientId: "701c81ab-6402-4fc2-b984-7f69ef0affa3",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://marval-stg.sumersports.com/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/vault\.marval-stg\.sumersports\.com/,
    config: {
      clubCode: "CAR",
      clubId: "b0afc250-bc45-565b-ab31-c09e2d5209e8",
      environment: {
        name: "stg-panthers",
      },
      services: {
        url: "https://panthers.api.staging.sumersports.com",
      },
      azureDirectory: {
        clientId: "701c81ab-6402-4fc2-b984-7f69ef0affa3",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://marval-stg.sumersports.com/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/hickory\.marval-stg\.sumersports\.com/,
    config: {
      clubCode: "JAX",
      clubId: "b0d7698e-edf7-5afe-98fe-85827218adb9",
      environment: {
        name: "stg-jaguars",
      },
      services: {
        url: "https://jaguars.api.staging.sumersports.com",
      },
      azureDirectory: {
        clientId: "701c81ab-6402-4fc2-b984-7f69ef0affa3",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://marval-stg.sumersports.com/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/foghorn\.marval-dev\.sumersports\.com/,
    config: {
      clubCode: "SF",
      clubId: "88397334-0092-5b55-8ce1-2bd467e0edf8",
      environment: {
        name: "dev-49ers",
      },
      services: {
        url: "https://sumer.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "6fb0fa03-36aa-46c5-b7fb-3684b25d9e85",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://6fb0fa03-36aa-46c5-b7fb-3684b25d9e85/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/cardinals\.marval\.sumersports\.com/,
    config: {
      clubCode: "ARZ",
      clubId: "29bc477a-e5b7-57dd-b73c-ad9783e85b3c",
      environment: {
        name: "cardinals",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/ravens\.marval\.sumersports\.com/,
    config: {
      clubCode: "BLT",
      clubId: "a2c18dc9-2b95-595a-91c1-c07d8bf10ffc",
      environment: {
        name: "ravens",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/falcons\.marval\.sumersports\.com/,
    config: {
      clubCode: "ATL",
      clubId: "05b24043-bb4c-50e7-88f6-f2e43146b5a2",
      environment: {
        name: "falcons",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/bills\.marval\.sumersports\.com/,
    config: {
      clubCode: "BUF",
      clubId: "645fddd1-df20-5323-93e4-c7c176baa507",
      environment: {
        name: "bills",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/panthers\.marval\.sumersports\.com/,
    config: {
      clubCode: "CAR",
      clubId: "b0afc250-bc45-565b-ab31-c09e2d5209e8",
      environment: {
        name: "panthers",
      },
      services: {
        url: "https://panthers.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/bengals\.marval\.sumersports\.com/,
    config: {
      clubCode: "CIN",
      clubId: "d6ee26d1-1c8a-5599-a3bd-1537e7de85bc",
      environment: {
        name: "bengals",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/bears\.marval\.sumersports\.com/,
    config: {
      clubCode: "CHI",
      clubId: "a939ecc7-fdbe-5f71-af12-b480efb5ffeb",
      environment: {
        name: "bears",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/browns\.marval\.sumersports\.com/,
    config: {
      clubCode: "CLV",
      clubId: "becdb626-1b97-5904-9eed-71842375bc39",
      environment: {
        name: "browns",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/cowboys\.marval\.sumersports\.com/,
    config: {
      clubCode: "DAL",
      clubId: "1afbdd15-d429-5bcb-a55b-af7199345da5",
      environment: {
        name: "cowboys",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/broncos\.marval\.sumersports\.com/,
    config: {
      clubCode: "DEN",
      clubId: "30a0f7e1-9843-5edd-8cf1-c60918b7f861",
      environment: {
        name: "broncos",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/lions\.marval\.sumersports\.com/,
    config: {
      clubCode: "DET",
      clubId: "9bbd6453-9703-5015-8a11-1a607b8805bd",
      environment: {
        name: "lions",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/texans\.marval\.sumersports\.com/,
    config: {
      clubCode: "HST",
      clubId: "e871178d-ca00-52ff-9e93-e3f7a8a9bc9f",
      environment: {
        name: "texans",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/packers\.marval\.sumersports\.com/,
    config: {
      clubCode: "GB",
      clubId: "63d59843-d136-5f8b-9c55-de09a20ab321",
      environment: {
        name: "packers",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/colts\.marval\.sumersports\.com/,
    config: {
      clubCode: "IND",
      clubId: "632cbb59-f592-5648-b181-6b23bdf9d85d",
      environment: {
        name: "colts",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/rams\.marval\.sumersports\.com/,
    config: {
      clubCode: "LA",
      clubId: "252e01fc-2471-563e-aa87-4876e74fbf06",
      environment: {
        name: "rams",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/jaguars\.marval\.sumersports\.com/,
    config: {
      clubCode: "JAX",
      clubId: "b0d7698e-edf7-5afe-98fe-85827218adb9",
      environment: {
        name: "jaguars",
      },
      services: {
        url: "https://jaguars.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/vikings\.marval\.sumersports\.com/,
    config: {
      clubCode: "MIN",
      clubId: "2315da89-83d2-5174-b6a7-1e81d98032a2",
      environment: {
        name: "vikings",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/chiefs\.marval\.sumersports\.com/,
    config: {
      clubCode: "KC",
      clubId: "3baabdb4-8c73-58f8-8e87-8db435aefb16",
      environment: {
        name: "chiefs",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/saints\.marval\.sumersports\.com/,
    config: {
      clubCode: "NO",
      clubId: "361e23bb-82f8-554d-b60d-24ac2d7679c3",
      environment: {
        name: "saints",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/raiders\.marval\.sumersports\.com/,
    config: {
      clubCode: "LV",
      clubId: "bb3667cc-9ac6-5170-9572-2b840882facd",
      environment: {
        name: "raiders",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/giants\.marval\.sumersports\.com/,
    config: {
      clubCode: "NYG",
      clubId: "8f5bf2a9-6ce9-5164-988e-2e0062f32090",
      environment: {
        name: "giants",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/chargers\.marval\.sumersports\.com/,
    config: {
      clubCode: "LAC",
      clubId: "dd0c9c81-33c9-5cc9-bee2-7c26a6327f6c",
      environment: {
        name: "chargers",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/eagles\.marval\.sumersports\.com/,
    config: {
      clubCode: "PHI",
      clubId: "eb0beb8f-2a1e-51e2-9ab2-793f72994559",
      environment: {
        name: "eagles",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/dolphins\.marval\.sumersports\.com/,
    config: {
      clubCode: "MIA",
      clubId: "4857a921-f6c9-5357-8527-f8589332184b",
      environment: {
        name: "dolphins",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/49ers\.marval\.sumersports\.com/,
    config: {
      clubCode: "SF",
      clubId: "88397334-0092-5b55-8ce1-2bd467e0edf8",
      environment: {
        name: "49ers",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/patriots\.marval\.sumersports\.com/,
    config: {
      clubCode: "NE",
      clubId: "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
      environment: {
        name: "patriots",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/seahawks\.marval\.sumersports\.com/,
    config: {
      clubCode: "SEA",
      clubId: "bc17557b-b749-58b5-9404-8682a796a9d2",
      environment: {
        name: "seahawks",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/jets\.marval\.sumersports\.com/,
    config: {
      clubCode: "NYJ",
      clubId: "84f58345-4a56-5c30-92b4-7365ac46b68f",
      environment: {
        name: "jets",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/buccaneers\.marval\.sumersports\.com/,
    config: {
      clubCode: "TB",
      clubId: "07f46f9d-1c41-534e-ab68-574e82c94a46",
      environment: {
        name: "buccaneers",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/steelers\.marval\.sumersports\.com/,
    config: {
      clubCode: "PIT",
      clubId: "995a2dd0-93bd-5651-a60d-6708b62b17b3",
      environment: {
        name: "steelers",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/commanders\.marval\.sumersports\.com/,
    config: {
      clubCode: "WAS",
      clubId: "e3003a6c-2896-5b55-9ede-2d9a2b16dca2",
      environment: {
        name: "commanders",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/titans\.marval\.sumersports\.com/,
    config: {
      clubCode: "TEN",
      clubId: "b4a5ebdf-c1ab-5046-9985-a69f83a44ace",
      environment: {
        name: "titans",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
  {
    domain: /^https:\/\/clubsumer\.marval\.sumersports\.com/,
    config: {
      clubCode: "NE",
      clubId: "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
      environment: {
        name: "prd-clubsumer",
      },
      services: {
        url: "https://sumer.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "b0ad7447-f1f8-4f65-94bc-7626bf3ef7af",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: [
          "api://b0ad7447-f1f8-4f65-94bc-7626bf3ef7af/readwrite",
        ],
      },
    },
  },
];

// Pre-render is done using the default config (at build time)
// client runtime will load the dynamic config envs based on the domain
// the site is currently hosted at
let runtimeConfig = defaultConfigurationContext;
if (typeof window !== "undefined") {
  runtimeConfig = lookupConfigByDomain(window.location.toString());
}

export const clientConfig = Object.freeze(runtimeConfig);

export const CDN_PATH = "https://cdn.sumersports.com/assets";

export const MUI_LICENSE =
  "4254d47b44395eb92f55b5fcd88a21b7Tz02ODc0MSxFPTE3MTg0NjQwNTUyODAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
